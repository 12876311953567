import React from "react";
import { Modal, Button, Stack } from "react-bootstrap";

const UnsubscribeModal = ({ show, onHide, onConfirmCancel }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Stack direction="vertical" gap={2} className="w-100">
          <Button variant="dark" className="w-100" onClick={onHide}>
            Keep My Plan
          </Button>
          <Button
            variant="outline-secondary"
            className="w-100"
            onClick={onConfirmCancel}
          >
            Cancel Plan
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default UnsubscribeModal;
