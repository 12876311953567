import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import AuthenticateSession from "./auth/AuthenticateSession";
import { AuthProvider } from "./auth/AuthContext";
import Portal from "./portal/Portal";
import NewPaymentMethod from "./payment_methods/NewPaymentMethod";
import SessionExpired from "./portal/SessionExpired";
import Header from "./portal/Header";
import { AlertProvider } from "./portal/AlertContext";

function App() {
  return (
    <AuthProvider>
      <AlertProvider>
        <Header />
        <Router>
          <Routes>
            <Route path="/" element={<AuthenticateSession />} />
            <Route
              path="/portal"
              element={
                <PrivateRoute>
                  <Portal />
                </PrivateRoute>
              }
            />
            <Route
              path="/portal/new-payment-method"
              element={
                <PrivateRoute>
                  <NewPaymentMethod />
                </PrivateRoute>
              }
            />
            <Route path="/session-expired" element={<SessionExpired />} />
            <Route path="*" element={<Navigate to="/portal" replace />} />
          </Routes>
        </Router>
      </AlertProvider>
    </AuthProvider>
  );
}

export default App;
