import React, { useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { FaCcMastercard } from "react-icons/fa";
import { RiVisaLine } from "react-icons/ri";
import { FaPaypal, FaApple, FaGoogle } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import DeletePaymentMethodModal from "./DeletePaymentMethodModal";
import { deletePaymentMethod } from "../api";
import { useAlert } from "../portal/AlertContext";

const PaymentIcon = ({ type, network }) => {
  switch (type) {
    case "card":
      switch (network) {
        case "VISA":
          return <RiVisaLine size={34} />;
        case "MASTERCARD":
          return <FaCcMastercard size={34} />;
        default:
          return <CiCreditCard1 size={34} />;
      }
    case "paypal":
      return <FaPaypal size={34} />;
    case "apple_pay":
      return <FaApple size={34} />;
    case "google_pay":
      return <FaGoogle size={34} />;
    default:
      return <CiCreditCard1 size={34} />;
  }
};

const PaymentMethod = ({ paymentMethod, onAction }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showAlert } = useAlert();

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleConfirmDeletion = async () => {
    try {
      await deletePaymentMethod(paymentMethod.id);
      onAction();
      handleCloseDeleteModal();
    } catch (error) {
      showAlert("Failed to delete payment method, try again later.");
      console.error("Error occurred while pause:", error);
    }
  };

  return (
    <Card className="mt-3 shadow-sm">
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <PaymentIcon
                    type={paymentMethod.type}
                    network={paymentMethod.network}
                  />
                </div>
                <h5
                  className="mb-0"
                  style={{
                    overflowWrap: "break-word",
                    wordBreak: "break-word",
                  }}
                >
                  {paymentMethod.type === "card"
                    ? `•••• ${paymentMethod.last4}`
                    : paymentMethod.email}
                </h5>
              </div>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={handleShowDeleteModal}
              >
                <MdClose size={24} />
              </Button>
            </div>

            <DeletePaymentMethodModal
              show={showDeleteModal}
              onHide={handleCloseDeleteModal}
              onConfirm={handleConfirmDeletion}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PaymentMethod;
