import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createSession } from "../api";
import { useAuth } from "./AuthContext";

const AuthenticateSession = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (isAuthenticated && !token) {
      navigate("/portal");
      return;
    }

    const authenticate = async (token) => {
      if (!token) {
        console.error("Token not found in URL");
        navigate("/session-expired");
        return;
      }

      try {
        const data = await createSession(token);
        localStorage.setItem("session", data.session);
        console.log("Authentication successful:", data);
        login();
        navigate("/portal");
      } catch (error) {
        console.error("Authentication error:", error);
        navigate("/session-expired");
      }
    };

    authenticate(token);
  }, [isAuthenticated, location.search, login, navigate]);
};

export default AuthenticateSession;
