import Subscription from "./Subscription";
import React, { useState, useEffect } from "react";
import { getSubscriptions } from "../api";
import { Alert, Spinner } from "react-bootstrap";
import { useAlert } from "../portal/AlertContext";

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showAlert } = useAlert();

  const refreshSubscriptions = () => {
    setLoading(true);
    getSubscriptions()
      .then((data) => {
        setSubscriptions(data.subscriptions);
        setLoading(false);
      })
      .catch((error) => {
        showAlert("Failed to fetch subscriptions, try again later.");
        console.error("Failed to fetch subscriptions:", error);
      });
  };

  useEffect(() => {
    refreshSubscriptions();
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner animation="grow" variant="secondary" />
      </div>
    );
  }

  if (subscriptions.length === 0) {
    return (
      <Alert variant="light" className={"mt-3"}>
        No subscriptions found
      </Alert>
    );
  }

  return (
    <div>
      <h4>Subscriptions</h4>

      {subscriptions.map((subscription) => (
        <Subscription key={subscription.id} _subscription={subscription} />
      ))}
    </div>
  );
};

export default Subscriptions;
