import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { createPaymentClient } from "@palta-brain/payments";
import { jwtDecode } from "jwt-decode";
import {API_BASE_URL} from "../api";

const PaymentMethodForm = () => {
  const containerId = "checkout-container";

  useEffect(() => {
    const containerElement = document.getElementById(containerId);
    console.log(containerElement);
    if (containerElement) {
      containerElement.innerHTML = "";
    }
  }, []);

  const orderId = uuidv4();
  const session = localStorage.getItem("session");
  const sessionData = jwtDecode(session);
  const customerId = sessionData.customer_id;
  const customerIdType = sessionData.customer_id_type;
  const publicKey = sessionData.public_key;

  const settings = {
    apiEndpoint: API_BASE_URL,
    apiKey: publicKey,
    metadata: {
      paypal: 1,
      apple: 1,
      google: 1,
    },
    onError: (error, description) => {
      console.log(description);
      console.log(error);
    },
    onPaymentStatusChange: (status, paymentMethodType) => {
      console.log("Payment status", status);
      console.log("Payment method type", paymentMethodType);
    },
  };

  const client = createPaymentClient(settings);

  client.showPaymentForm(
    {
      ident: "payment_method_update",
      orderId: orderId,
      customerId: { type: customerIdType, value: customerId },
    },
    {
      container: `#${containerId}`,
      vault: { visible: false },
      paypal: {
        buttonColor: "black",
        buttonShape: "pill",
        buttonSize: "large",
        paymentFlow: "PREFER_VAULT",
      },
    },
  );
};

export default PaymentMethodForm;
