import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FiLogIn } from "react-icons/fi";

const SessionExpired = () => {
  return (
    <Container className="d-flex vh-100" style={{ alignItems: "flex-start" }}>
      <Row className="w-100" style={{ marginTop: "15vh" }}>
        <Col xs={12} className="text-center">
          <FiLogIn size={70} />
          <h1>Session Expired</h1>
          <p>
            Your session has expired. Please return to application to continue.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SessionExpired;
