import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const PauseModal = ({ show, onHide, onConfirmPause }) => {
  const [pauseUntilDate, setPauseUntilDate] = useState("");

  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 5))
    .toISOString()
    .split("T")[0];

  const currentDate = new Date();
  const tomorrow = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  const minDate = tomorrow.toISOString().split("T")[0];

  const handleConfirm = () => {
    onConfirmPause(pauseUntilDate);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Pause Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Pause until</Form.Label>
            <Form.Control
              type="date"
              value={pauseUntilDate}
              onChange={(e) => setPauseUntilDate(e.target.value)}
              max={maxDate}
              min={minDate}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="dark" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PauseModal;
