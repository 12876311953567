import React, { useState, useEffect } from "react";
import { getPaymentMethods } from "../api";
import PaymentMethod from "./PaymentMethod";
import { Alert, Spinner, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../portal/AlertContext";

const PaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const refreshPaymentMethods = () => {
    setLoading(true);
    getPaymentMethods()
      .then((data) => {
        setPaymentMethods(data.paymentMethods);
        setLoading(false);
      })
      .catch((error) => {
        showAlert("Failed to fetch payment methods, try again later.");
        console.error("Failed to fetch payment methods:", error);
      });
  };

  useEffect(() => {
    refreshPaymentMethods();
  }, []);

  const navigateToAddPaymentMethod = () => {
    navigate("/portal/new-payment-method");
  };

  if (loading) {
    return (
      <div>
        <Spinner animation="grow" variant="secondary" />
      </div>
    );
  }

  if (paymentMethods.length === 0) {
    return (
      <div>
        <Alert variant="light" className={"mt-3"}>
          No payment methods saved
        </Alert>
        <div className="d-flex justify-content-end mt-2">
          <Button variant="outline-dark" onClick={navigateToAddPaymentMethod}>
            Add Payment Method
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {paymentMethods.map((paymentMethod) => (
        <PaymentMethod
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
          onAction={refreshPaymentMethods}
        />
      ))}
      <div className="d-flex justify-content-end mt-2">
        <Button variant="outline-dark" onClick={navigateToAddPaymentMethod}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethods;
