import React, { useState } from "react";
import { Card, Button, Row, Col, Spinner } from "react-bootstrap";
import {
  MdPauseCircleOutline,
  MdCancel,
  MdPlayCircleOutline,
  MdRestartAlt,
} from "react-icons/md";
import FormattedDate from "../utils";
import UnsubscribeModal from "./UnsubscribeModal";
import {
  softResetSubscription,
  pauseSubscription,
  unsubscribeSubscription,
  resumeSubscription,
  getSubscription,
} from "../api";
import PauseModal from "./PauseModal";
import { useAlert } from "../portal/AlertContext";

const Subscription = ({ _subscription }) => {
  const [subscription, setSubscription] = useState(_subscription);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);

  const refreshSubscription = () => {
    setLoading(true);
    getSubscription(subscription.id)
      .then((data) => {
        setSubscription(data.subscription);
        setLoading(false);
      })
      .catch((error) => {
        showAlert("Failed to fetch subscription, try again later.");
        console.error("Failed to fetch subscription:", error);
      });
  };

  const onUnsubscribeSubscription = async () => {
    try {
      await unsubscribeSubscription(subscription.id);
      refreshSubscription();
    } catch (error) {
      showAlert("Failed to unsubscribe, try again later.");
      console.error("Error occurred while unsubscribing:", error);
    }
  };

  const onResumeSubscription = async () => {
    try {
      await resumeSubscription(subscription.id);
      refreshSubscription();
    } catch (error) {
      showAlert("Failed to resume subscription, try again later.");
      console.error("Error occurred while resume", error);
    }
  };

  const onSoftResetSubscription = async () => {
    try {
      await softResetSubscription(subscription.id);
      refreshSubscription();
    } catch (error) {
      showAlert("Failed to reset subscription, try again later.");
      console.error("Error occurred while reset:", error);
    }
  };

  const handleConfirmPause = async (pauseUntilDate) => {
    if (pauseUntilDate) {
      try {
        await pauseSubscription(subscription.id, pauseUntilDate);
        refreshSubscription();
        handleClosePauseModal();
      } catch (error) {
        showAlert("Failed to pause subscription, try again later.");
        console.error("Error occurred while pause:", error);
      }
    }
  };

  const handleShowUnsubscribeModal = () => setShowUnsubscribeModal(true);
  const handleCloseUnsubscribeModal = () => setShowUnsubscribeModal(false);
  const handleConfirmUnsubscribe = () => {
    onUnsubscribeSubscription();
    handleCloseUnsubscribeModal();
  };

  const handleShowPauseModal = () => setShowPauseModal(true);
  const handleClosePauseModal = () => setShowPauseModal(false);

  const status = subscription.status;
  const pricePoint = subscription.pricePoint;

  const isPaused = status.includes("is_paused");
  const isUnsubscribed = status.includes("is_unsubscribed");

  return (
    <Card className="mt-3 shadow-sm">
      <Card.Body>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading..</span>
            </Spinner>
          </div>
        ) : (
          <Row className="align-items-center">
            <Col xs={12} lg={8} className="mb-3 mb-lg-0">
              <Card.Title>{pricePoint.name}</Card.Title>

              {isPaused && (
                <Card.Text className="text-muted">
                  Paused Until:{" "}
                  <b>
                    <FormattedDate dateString={subscription.pausedUntil} />
                  </b>
                </Card.Text>
              )}

              {isUnsubscribed && !isPaused && (
                <Card.Text className="text-muted">
                  Expiration date:{" "}
                  <b>
                    <FormattedDate
                      dateString={subscription.currentPeriodEndAt}
                    />
                  </b>
                </Card.Text>
              )}

              {!isUnsubscribed && !isPaused && (
                <Card.Text className="text-muted">
                  Your next invoice dated:{" "}
                  <b>
                    <FormattedDate
                      dateString={subscription.currentPeriodEndAt}
                    />
                  </b>
                </Card.Text>
              )}
            </Col>
            <Col xs={12} lg={4} className="text-end">
              {!isPaused && (
                <Button
                  variant="outline-dark"
                  className="me-2"
                  onClick={() => handleShowPauseModal()}
                >
                  <MdPauseCircleOutline size={24} /> Pause
                </Button>
              )}

              {isPaused && (
                <Button
                  variant="outline-dark"
                  className="me-2"
                  onClick={() => onResumeSubscription()}
                >
                  <MdPlayCircleOutline size={24} /> Resume
                </Button>
              )}

              {!isUnsubscribed && (
                <Button variant="dark" onClick={handleShowUnsubscribeModal}>
                  <MdCancel size={24} /> Unsubscribe
                </Button>
              )}

              {isUnsubscribed && (
                <Button
                  variant="dark"
                  onClick={() => onSoftResetSubscription()}
                >
                  <MdRestartAlt size={24} /> Reset
                </Button>
              )}

              <PauseModal
                show={showPauseModal}
                onHide={handleClosePauseModal}
                onConfirmPause={handleConfirmPause}
              />

              <UnsubscribeModal
                show={showUnsubscribeModal}
                onHide={handleCloseUnsubscribeModal}
                onConfirmCancel={handleConfirmUnsubscribe}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default Subscription;
