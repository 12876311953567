export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchWithAuth = async (url, options = {}) => {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
      "Portal-Authorization": `Bearer ${localStorage.getItem("session")}`,
    },
  });

  if (response.status === 401) {
    localStorage.removeItem("session");
    window.location.href = "/session-expired";
  }

  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }

  return response;
};

export async function createSession(token) {
  const response = await fetch(`${API_BASE_URL}/customer-portal/auth/session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  });

  if (!response.ok) {
    throw new Error("Failed to create session");
  }

  return await response.json();
}

export async function getSubscriptions() {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/subscriptions`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export async function getSubscription(id) {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/subscriptions/${id}`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export async function pauseSubscription(id, pauseUntil) {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/subscriptions/${id}/pause`,
    {
      method: "POST",
      body: JSON.stringify({ pauseUntil }),
    },
  );
  return await response.json();
}

export async function resumeSubscription(id) {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/subscriptions/${id}/resume`,
    {
      method: "POST",
    },
  );
  return await response.json();
}

export async function unsubscribeSubscription(id) {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/subscriptions/${id}/unsubscribe`,
    {
      method: "POST",
    },
  );
  return await response.json();
}

export async function softResetSubscription(id) {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/subscriptions/${id}/soft-reset`,
    {
      method: "POST",
    },
  );
  return await response.json();
}

export async function getPaymentMethods() {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/payment-methods`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export async function deletePaymentMethod(id) {
  const response = await fetchWithAuth(
    `${API_BASE_URL}/customer-portal/payment-methods/${id}`,
    {
      method: "DELETE",
    },
  );
  return await response.json();
}
