import React from "react";
import { Alert } from "react-bootstrap";
import { useAlert } from "./AlertContext";

const GlobalAlert = () => {
  const { alert, hideAlert } = useAlert();

  if (!alert) return null;

  return (
    <Alert
      className={"mt-3"}
      variant={"warning"}
      onClose={hideAlert}
      dismissible
    >
      {alert.message}
    </Alert>
  );
};

export default GlobalAlert;
