import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { RiSecurePaymentLine } from "react-icons/ri";

const Header = () => (
  <Navbar bg="dark" expand="lg">
    <Container>
      <Navbar.Brand>
        <RiSecurePaymentLine size={40} color={"white"} />{" "}
        <span style={{ color: "white" }}>Customer Portal</span>
      </Navbar.Brand>
    </Container>
  </Navbar>
);

export default Header;
