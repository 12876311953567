import Subscriptions from "../subscriptions/Subscriptions";
import PaymentMethods from "../payment_methods/PaymentMethods";
import React from "react";
import GlobalAlert from "./GlobalAlert";

import { Container } from "react-bootstrap";

const Portal = () => {
  return (
    <div>
      <Container>
        <GlobalAlert />
        <PaymentMethods />
        <Subscriptions />
      </Container>
    </div>
  );
};

export default Portal;
