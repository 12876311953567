import React, { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

const isTokenValid = (session) => {
  const decodedToken = jwtDecode(session);
  const expireAt = new Date(decodedToken.exp * 1000);
  const currentTime = new Date();
  return currentTime < expireAt;
};

export const AuthProvider = ({ children }) => {
  const session = localStorage.getItem("session");
  let isAuthenticatedDefault = false;
  if (session && isTokenValid(session)) {
    isAuthenticatedDefault = true;
  } else {
    localStorage.removeItem("session");
  }

  const [isAuthenticated, setIsAuthenticated] = useState(
    isAuthenticatedDefault,
  );

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
