import PaymentMethodForm from "./PaymentMethodForm";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React from "react";

const NewPaymentMethod = () => {
  const navigate = useNavigate();

  const navigateToPortal = () => {
    navigate("/portal");
  };

  return (
    <Container>
      <Card className="mt-3 shadow-sm">
        <Card.Body>
          <div id="checkout-container"></div>
        </Card.Body>
      </Card>

      <PaymentMethodForm />

      <Button
        variant="outline-dark"
        className={"mt-2"}
        onClick={navigateToPortal}
      >
        Back
      </Button>
    </Container>
  );
};

export default NewPaymentMethod;
